import React from "react"
import NavBar from "../components/NavBar"
import Metatags from "../components/metatags"
import Footer from "../components/Footer.es"

const IndexEN = ({ location }) => {
  return (
    <>
      <Metatags
        title="Multilógica | Política de Privacidad"
        description="Proveedor de maquinaria para colchones"
        url="https://newsite.multilogica.ind.br/es/politica-de-privacidad"
        image="https://newsite.multilogica.ind.br/Multilogica-icon.png"
        keywords="colchón, máquina de coser, mammut, beckmann sew"
        language="es"
      />
      <NavBar location={location["pathname"]} />
      <div className="h-20" />
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4 mx-2">
          <div className="col-span-2" />
          <div className="col-span-8 text-center">
            <div className="text-5xl font-semibold text-mi-blue">
              Política de Privacidade
            </div>
            <div className=" h-8" />
            <div className=" text-left text-xl">
              <p className=" font-bold">Início</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>
                POLÍTICA DE PRIVACIDAD – Multilógica Comercio de Materiales
                Eléctricos y Servicios Ltda ME
              </p>
              <p>&nbsp;</p>
              <p>
                Multilógica Comercio de Materiales Eléctricos y Servicios Ltda
                ME, una empresa registrada bajo el CNPJ/MF nº
                64.880.743/0001-86, en adelante denominada Multilógica, valora
                la privacidad de sus usuarios, clientes, proveedores y
                empleados, por lo que ha creado esta Política de Privacidad de
                acuerdo con la Ley General de Protección de Datos nº
                13.709/2018.
              </p>
              <p>&nbsp;</p>
              <p>
                Cuando te registras o te suscribes al boletín de Multilógica,
                nos proporcionas algunos datos personales con el objetivo de
                viabilizar tu compra. Multilógica tiene como objetivo demostrar
                su compromiso con la seguridad, privacidad, transparencia y el
                tratamiento de los datos personales, por lo que dedicamos este
                documento a explicar al titular de los datos qué datos se
                recopilan, cómo se recopilan, cuál es el tratamiento dado, cómo
                es la forma de almacenamiento, si hay algún compartimiento con
                terceros, entre otras informaciones esenciales en relación con
                los datos personales. El usuario declara que ha leído completa y
                atentamente, estando plenamente consciente de la presente
                Política de Privacidad y Tratamiento de Datos Personales,
                otorgando, expresamente, su libre, inequívoca e informada
                conformidad con los términos aquí estipulados, autorizando la
                obtención de los datos personales e informaciones aquí
                mencionados, así como su utilización para los fines
                especificados a continuación. En caso de no estar de acuerdo con
                estas directrices, el usuario debe descontinuar su acceso al
                sitio web de Multilógica.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">ÍNDICE 1.</p>
              <p>&nbsp;</p>
              <p>INFORMACIÓN GENERAL Y DEFINICIONES</p>
              <p>2. RECOLECCIÓN Y TRATAMIENTO DE DATOS</p>
              <p>3. ALMACENAMIENTO DE DATOS</p>
              <p>4. COMPARTICIÓN DE DATOS</p>
              <p>5. HIPÓTESIS LEGALES PARA LA DIVULGACIÓN DE DATOS</p>
              <p>6. RETENCIÓN DE DATOS</p>
              <p>7. DERECHOS DEL TITULAR</p>
              <p>8. COOKIES</p>
              <p>9. REVISIÓN DE LA POLÍTICA</p>
              <p>10. CONTACTO</p>
              <p>11. JURISDICCIÓN Y LEGISLACIÓN</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">
                1. INFORMACIÓN GENERAL Y DEFINICIONES
              </p>
              <p>&nbsp;</p>
              <p>
                Usuario: todas las personas físicas que utilizarán o visitarán
                el sitio, mayores de 18 (dieciocho) años o emancipadas y
                totalmente capaces de realizar los actos de la vida civil o los
                absolutamente o relativamente incapaces debidamente
                representados o asistidos.
              </p>
              <p>&nbsp;</p>
              <p>
                Datos Personales: significan cualquier información proporcionada
                y/o recopilada por Multilógica, por cualquier medio, incluso si
                es pública, que: (I) identifique, o que, cuando se use en
                combinación con otra información tratada por Multilógica,
                identifique a un individuo; o (II) a través de la cual se pueda
                derivar la identificación o información de contacto de una
                persona física. Los Datos Personales pueden estar en cualquier
                medio o formato, incluidos registros electrónicos o
                informatizados, así como en archivos basados en papel. Sin
                embargo, los Datos Personales no incluyen teléfono comercial,
                número de celular comercial, dirección comercial, correo
                electrónico comercial.
              </p>
              <p>&nbsp;</p>
              <p>
                Finalidad: el objetivo, el propósito que Multilógica desea
                alcanzar a partir de cada acto de tratamiento de la información
                personal.
              </p>
              <p>&nbsp;</p>
              <p>
                Necesidad: justificación por la cual es estrictamente necesario
                recopilar datos personales, para alcanzar la finalidad, evitando
                la recopilación excesiva.
              </p>
              <p>&nbsp;</p>
              <p>
                Bases legales: fundamento legal que hace legítimo el tratamiento
                de datos personales para una determinada finalidad previa por
                parte de Multilógica.
              </p>
              <p>&nbsp;</p>
              <p>
                Consentimiento: autorización expresa e inequívoca dada por el
                Usuario titular del dato personal para que Multilógica trate sus
                datos personales para una finalidad previamente descrita, en la
                cual la base legal necesaria para el acto requiera la
                autorización expresa del titular.
              </p>
              <p>&nbsp;</p>
              <p>
                Al acceder y/o utilizar el sitio de Multilógica, el Usuario
                declara tener al menos 18 (dieciocho) años y tener plena
                capacidad y expresa para la aceptación de los términos y
                condiciones de esta Política de Privacidad y del Término de
                Consentimiento para todos los fines de derecho.
              </p>
              <p>&nbsp;</p>
              <p>
                En caso de que el Usuario no se ajuste a la descripción anterior
                y/o no esté de acuerdo, aunque sea en parte, con los términos y
                condiciones contenidos en esta Política de Privacidad, no deberá
                acceder y/o utilizar cualquier servicio ofrecido por
                Multilógica.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">
                2. RECOLECCIÓN Y TRATAMIENTO DE DATOS
              </p>
              <p>&nbsp;</p>
              <p>
                El Usuario es consciente de que proporciona información de forma
                consciente y voluntaria a través de formularios de registro, o a
                través del sitio operado por Multilógica.
              </p>
              <p>&nbsp;</p>
              <p>
                Cuando el Usuario realiza el registro y/o completa formularios
                ofrecidos por Multilógica, incluso en el sitio web operado por
                ella, ciertos Datos Personales solicitados serán mantenidos en
                confidencialidad y serán utilizados solo para los propósitos
                definidos a continuación:
              </p>
              <p>&nbsp;</p>
              <p>
                Datos recopilados: Nombre Personal, Razón Social, correo
                electrónico, teléfono y nombre de la empresa en la que trabaja
                (CLIENTES y USUARIOS DEL SITIO WEB) Finalidad del
                uso/tratamiento de datos: Para poder acceder a contenidos
                informativos sobre tributación, eventos, promociones y noticias
                relevantes para Multilógica, el Usuario deberá proporcionar su
                Nombre, Correo Electrónico, teléfono y en qué Empresa trabaja.
                Multilógica enviará correos electrónicos de marketing a los
                usuarios que proporcionen esta información. Los Datos Personales
                recopilados no serán utilizados para enviar ningún tipo de SPAM
                u otro fin que no sea el aquí expuesto. Base Legal:
                Consentimiento Consentimiento: Estoy de acuerdo en proporcionar
                mi Nombre, Correo Electrónico y en qué Empresa trabajo para
                acceder al material disponible en el sitio, además de recibir el
                Boletín de Multilógica y otros correos electrónicos de
                marketing. Estoy consciente de que los datos proporcionados
                serán utilizados internamente con fines promocionales por
                Multilógica y acepto recibir correos electrónicos de
                Multilógica.
              </p>
              <p>&nbsp;</p>
              <p>
                Datos recopilados: Nombre/Razón Social, teléfono, código postal
                y correo electrónico (SAC) Finalidad del uso/tratamiento de
                datos: En caso de que el titular de los datos quiera ponerse en
                contacto con la empresa por cualquier medio (teléfono,
                presencial o vía sitio web) para solicitar información. Base
                Legal: Consentimiento Consentimiento: Estoy de acuerdo en
                proporcionar mi nombre y correo electrónico para que Multilógica
                responda a mi contacto/solicitud de información.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">3. ALMACENAMIENTO DE DATOS</p>
              <p>&nbsp;</p>
              <p>
                Multilógica almacenará la información recopilada en servidores
                propios o contratados en la nube por los proveedores de
                servicios contratados por ella.
              </p>
              <p>&nbsp;</p>
              <p>
                Multilógica utiliza los medios razonables del mercado y
                legalmente requeridos para preservar la privacidad de los datos
                recopilados. De esta forma, adopta, por ejemplo, las siguientes
                precauciones:
              </p>
              <p>&nbsp;</p>
              <p>
                Cuando es necesario, Multilógica utiliza los métodos estándar y
                del mercado para encriptar y anonimizar los datos recopilados;
                Multilógica tiene protección contra el acceso no autorizado a
                sus sistemas; Multilógica solo autoriza el acceso de personas
                previamente establecidas al lugar donde se almacenan las
                informaciones recopiladas; Aquellos que entran en contacto con
                la información se comprometen a mantener absoluta
                confidencialidad. La violación de la confidencialidad acarreará
                responsabilidad civil y el responsable será responsabilizado
                según la legislación brasileña; Multilógica adopta los mejores
                esfuerzos para preservar la privacidad de los datos de los
                Usuarios. Sin embargo, ningún sitio es completamente seguro y
                Multilógica no puede garantizar completamente que toda la
                información que circula en las Páginas no sea objeto de accesos
                no autorizados perpetrados por medio de métodos desarrollados
                para obtener información de forma indebida.
              </p>
              <p>&nbsp;</p>
              <p>
                Por este motivo, incentivamos a los Usuarios a tomar las medidas
                apropiadas para protegerse, como, por ejemplo, manteniendo
                confidenciales todos los nombres de usuario y contraseñas
                creados.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">4. COMPARTICIÓN DE DATOS</p>
              <p>&nbsp;</p>
              <p>
                Multilógica podrá divulgar los datos personales recopilados a
                terceros en las siguientes situaciones y dentro de los límites
                autorizados por la ley: Para propósitos administrativos como:
                investigación, planificación, desarrollo de servicios,
                seguridad, emisión de facturas, banco, plataforma de pago y
                gestión de riesgos y otros socios comerciales o administrativos;
                Con socios comerciales con el objetivo de viabilizar la entrega
                de los productos adquiridos con Multilógica; Cuando sea
                necesario para cumplir con una obligación legal, determinación
                de autoridad competente o decisión judicial.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">
                5. HIPÓTESIS LEGALES PARA LA DIVULGACIÓN DE DATOS
              </p>
              <p>&nbsp;</p>
              <p>
                En algunos casos, Multilógica podrá divulgar los Datos
                Personales recopilados con el objetivo de cumplir con la
                legislación aplicable o por medio de orden o citación judicial o
                administrativa.
              </p>
              <p>&nbsp;</p>
              <p>La divulgación legal podrá hacerse para:</p>
              <p>&nbsp;</p>
              <p>
                Cumplir con la legislación; Investigar, impedir o tomar medidas
                relacionadas con la cooperación con organismos públicos o para
                proteger la seguridad nacional; Ejecución de contratos;
                Investigación y defensa de alegaciones de terceros; Protección
                de la seguridad o integridad de los servicios;
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">6. RETENCIÓN DE DATOS</p>
              <p>&nbsp;</p>
              <p>
                Multilógica retendrá la información de los Titulares de datos
                solo durante el período necesario para los fines para los cuales
                esa información fue tratada, incluyendo la seguridad del
                procesamiento, con obligaciones jurídicas y reglamentarias (por
                ejemplo, auditoría, contabilidad y términos de retención
                estatutaria), litigios relacionados con el procesamiento, y para
                el establecimiento, ejercicio o defensa de acciones judiciales.
              </p>
              <p>&nbsp;</p>
              <p>
                El titular de los datos podrá solicitar la eliminación de los
                datos por medio de una solicitud directa a la empresa a través
                del correo electrónico: contacto@multilogica.ind.br o teléfono
                (11) 4319-1041.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">7. DERECHOS DEL TITULAR</p>
              <p>&nbsp;</p>
              <p>
                En cumplimiento con la normativa aplicable, en lo que respecta
                al tratamiento de datos personales, Multilógica respeta y
                garantiza al Usuario la posibilidad de presentar solicitudes
                basadas en los siguientes derechos:
              </p>
              <p>&nbsp;</p>
              <p>
                la confirmación de la existencia de tratamiento; el acceso a los
                datos; la corrección de datos incompletos, inexactos o
                desactualizados; la anonimización, bloqueo o eliminación de
                datos innecesarios, excesivos o tratados en desacuerdo; la
                eliminación de los datos tratados con el consentimiento del
                Usuario; la obtención de información sobre las entidades
                públicas o privadas con las que Multilógica ha compartido sus
                datos; la información sobre la posibilidad de no proporcionar su
                consentimiento, así como de ser informado sobre las
                consecuencias, en caso de negativa; la revocación del
                consentimiento. Multilógica se pone a disposición para cumplir
                con los derechos de los titulares de datos mediante una
                solicitud del titular que debe enviarse al correo electrónico
                contacto@multilogica.ind.br o al teléfono (11) 4319-1041.
              </p>
              <p>&nbsp;</p>
              <p>
                Multilógica hará todos los esfuerzos para atender tales
                solicitudes en el menor tiempo posible.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">8. REVISIÓN DE LA POLÍTICA</p>
              <p>&nbsp;</p>
              <p>
                Si Multilógica modifica la presente política, tales cambios
                serán publicados de forma visible en el sitio web de
                Multilógica, y siempre que sea posible se informará al titular
                de los datos.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">9. COOKIES</p>
              <p>&nbsp;</p>
              <p>
                Las cookies son archivos o información que pueden almacenarse en
                sus dispositivos cuando visita las Páginas de Multilógica.
                Generalmente, una cookie contiene el nombre del sitio del que
                proviene, su tiempo de vida y un valor, que se genera
                aleatoriamente.
              </p>
              <p>&nbsp;</p>
              <p>
                Multilógica utiliza cookies para facilitar el uso y mejor
                adaptar las Páginas a sus intereses y necesidades, así como para
                compilar información sobre el uso de nuestro sitio, ayudando a
                mejorar sus estructuras y contenidos. Las cookies también pueden
                utilizarse para acelerar sus actividades y experiencias futuras
                en las Páginas.
              </p>
              <p>&nbsp;</p>
              <p>
                Tipos de cookies: Necesarias ¿Qué hacen? Estas cookies nos
                ayudan a entender cómo los visitantes interactúan con las
                Páginas de Multilógica, proporcionando información sobre las
                áreas visitadas, el tiempo de visita al sitio y cualquier
                problema encontrado, como mensajes de error.
              </p>
              <p>&nbsp;</p>
              <p>
                Tipos de cookies: Funcionales ¿Qué hacen? Estas cookies permiten
                que las Páginas de Multilógica recuerden sus elecciones, para
                proporcionar una experiencia más personalizada.
              </p>
              <p>&nbsp;</p>
              <p>
                Tipos de cookies: Marketing ¿Qué hacen? Estos cookies son
                utilizados para proporcionar contenido más relevante y de
                interés para los Usuarios. Pueden ser utilizados para presentar
                publicidad más dirigida al usuario. Además, permiten medir la
                eficacia de una campaña publicitaria de Multilógica. También,
                estos cookies pueden ser utilizados para indicar a las Páginas
                de Multilógica los sitios que el Usuario ha visitado.
              </p>
              <p>&nbsp;</p>
              <p>
                Después de que el Usuario consienta el uso de cookies al
                utilizar las Páginas de Multilógica, esta almacenará una cookie
                en su dispositivo para recordar esto en la próxima sesión.
              </p>
              <p>&nbsp;</p>
              <p>
                En cualquier momento, el Usuario podrá revocar su consentimiento
                respecto a las cookies, para lo cual deberá eliminar las cookies
                de las Páginas de Multilógica utilizando las configuraciones de
                su navegador preferido. Para más información sobre cómo proceder
                en relación con la gestión de cookies en los navegadores:
              </p>
              <p>&nbsp;</p>
              <p>Internet Explorer:</p>
              <p>
                <a
                  href="https://support.microsoft.com/pt-br/help/17442/windows-internetexplorer-delete-manage-cookies"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  https://support.microsoft.com/
                </a>
              </p>
              <p>&nbsp;</p>
              <p>Mozilla Firefox:</p>
              <p>
                <a
                  href="https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-queos-sites-usam"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  https://support.mozilla.org/
                </a>
              </p>
              <p>&nbsp;</p>
              <p>Google Chrome:</p>
              <p>
                <a
                  href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=pt-BR"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  https://support.google.com/
                </a>
              </p>
              <p>&nbsp;</p>
              <p>Safari:</p>
              <a
                href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac"
                rel="noopener noreferrer"
                target="_blank"
              >
                <p>https://support.apple.com/</p>
              </a>
              <p>&nbsp;</p>
              <p>
                Por último, recordamos que, si el Usuario no acepta algunos
                cookies de las Páginas de Multilógica, ciertos servicios pueden
                no funcionar de manera ideal.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">10. CONTACTO</p>
              <p>&nbsp;</p>
              <p>
                Multilógica se pone a disposición para resolver cualquier duda o
                solicitud de los titulares de datos a través del correo
                electrónico: contacto@multilogica.ind.br o por medio de contacto
                telefónico al número (11) 4319-1041.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">11. JURISDICCIÓN Y LEGISLACIÓN</p>
              <p>&nbsp;</p>
              <p>
                Esta Política será regida, interpretada y ejecutada de acuerdo
                con las Leyes de la República Federativa de Brasil,
                especialmente la Ley nº 13.709/2018, independientemente de las
                Leyes de otros estados o países, siendo competente el foro de
                Santo André, São Paulo para dirimir cualquier duda derivada de
                este documento.
              </p>
              <p>&nbsp;</p>
              <p>Última actualización: 10 de noviembre de 2021.</p>
              <p>&nbsp;</p>
              <p>
                Multilógica Comercio de Materiales Eléctricos y Servicios Ltda.
                - ME
              </p>
            </div>
          </div>
        </div>
        <div className="h-10" />
      </div>
      <div className="h-20" />
      <div className="h-10" />
      <Footer lang="en" />
    </>
  )
}

export default IndexEN
